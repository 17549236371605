import React from "react"
import classNames from "classnames"
import { FormattedMessage } from "gatsby-plugin-intl"
import { PlayStoreButton } from "./PlayStoreButton"
import { AppStoreButton } from "./AppStoreButton"
import deviceImage from "../assets/device-FooterBanner.png"

interface Props {
  className?: string
}

const CallToAction = ({ className }: Props) => {
    return (
        <section className={classNames("cta", className)}>
          <div className="container">
            <div className="cta-card">
              <div className="row">
                <div className="col">
                  <h5><FormattedMessage id="available_android_ios" defaultMessage="Available in Android & iOS" /></h5>
                  <h3><FormattedMessage id="cta" defaultMessage="Experience the full power of bitcoin." /></h3>
                  <AppStoreButton dark />
                  <PlayStoreButton dark />
                </div>
                <div className="col-md-auto d-none d-lg-block" style={{height: 0}}>
                  <img className="device-img" src={deviceImage} width="401" height="465" alt=""/>
                </div>
              </div>
            </div>
          </div>
        </section>
    )
}

export default CallToAction
